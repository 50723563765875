import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 988.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
<path d="M3910 8164 c-63 -7 -144 -18 -180 -23 -36 -6 -94 -15 -130 -20 -36
-5 -74 -12 -85 -15 -11 -2 -54 -10 -95 -16 -41 -7 -90 -17 -107 -22 l-33 -10
0 -139 c0 -136 0 -139 23 -143 12 -3 126 -7 252 -10 307 -8 467 -35 720 -123
263 -92 532 -260 740 -462 240 -234 358 -406 515 -756 27 -61 89 -274 116
-400 21 -96 25 -324 7 -405 -7 -30 -20 -91 -29 -135 -38 -182 -119 -376 -219
-530 -19 -28 -70 -88 -115 -132 -96 -96 -213 -163 -322 -183 -54 -10 -68 -16
-68 -30 0 -43 219 -195 339 -234 112 -38 253 -52 351 -37 189 29 334 106 510
273 128 120 248 326 333 568 34 97 60 264 64 425 4 103 9 163 17 167 6 4 246
8 533 8 l521 0 7 32 c9 37 -2 299 -16 413 -36 290 -157 639 -289 835 -9 14
-30 45 -46 70 -16 25 -31 47 -34 50 -3 3 -27 33 -53 67 -53 69 -246 261 -322
320 -205 160 -452 299 -675 380 -52 19 -111 41 -131 48 -107 40 -349 103 -489
127 -30 5 -77 14 -105 18 -199 36 -291 40 -840 38 -380 -1 -585 -5 -665 -14z"/>
<path d="M6580 2535 l0 -355 80 0 80 0 0 148 c0 129 2 151 20 180 24 39 60 50
120 39 50 -11 60 -45 60 -217 l0 -150 81 0 80 0 -3 203 -3 202 -30 42 c-34 48
-60 62 -125 70 -60 7 -118 -10 -157 -47 -18 -16 -34 -30 -37 -30 -3 0 -7 60
-8 133 l-3 132 -77 3 -78 3 0 -356z"/>
<path d="M4870 2780 l0 -70 100 0 100 0 0 -265 0 -265 85 0 85 0 0 265 0 265
100 0 100 0 0 70 0 70 -285 0 -285 0 0 -70z"/>
<path d="M5580 2681 c-65 -23 -130 -88 -148 -148 -15 -52 -15 -145 0 -197 16
-53 81 -118 141 -141 72 -27 208 -18 271 19 63 37 66 50 23 94 l-36 36 -40
-22 c-59 -33 -130 -30 -170 7 -17 16 -31 37 -31 48 0 17 11 18 172 18 l173 0
3 43 c4 60 -22 141 -58 180 -72 75 -193 101 -300 63z m145 -105 c36 -15 55
-42 55 -75 0 -20 -6 -21 -100 -21 -63 0 -100 4 -100 10 0 27 34 73 63 85 40
18 41 18 82 1z"/>
<path d="M6185 2691 c-73 -19 -127 -64 -164 -138 -65 -129 -14 -286 113 -348
50 -24 64 -27 138 -23 93 4 140 22 188 71 16 17 30 33 30 37 0 3 -25 20 -56
38 l-56 32 -30 -25 c-38 -32 -103 -34 -140 -5 -58 45 -64 147 -12 201 23 24
36 29 75 29 32 0 55 -6 74 -22 l27 -21 54 29 c30 16 54 34 54 39 0 18 -50 64
-93 87 -45 23 -150 33 -202 19z"/>

</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
